import { Actions, Model } from '@app-ngrx-domains';
import { isString, uniq, forOwn } from 'lodash';
import { DATE_TIME_MOMENT_FORMAT_STRINGS, NUM_REGEX, PROGRAM_KEYS, PROPOSAL_TYPES } from '@app-consts';
import { NewTempId } from '@app-libs';
import { EnumErrorTypes } from './app';
import { AppUtils } from '../utilities/app-utils';
import * as moment from 'moment-timezone';
import { ActivatedRouteSnapshot } from '@angular/router';

/**
 * This utitlities class contains static helper functions that work on known models.
 */
export class Utilities {
  /**
   * Returns the project key from the route.
   * @param route
   */
  static programKeyFromRoute(route: string, useSmallProgram?: boolean): string {
    let key = route.split('/')[1];
    if (key === PROGRAM_KEYS.SMALL_PROGRAMS && !useSmallProgram) {
      // If it's a small program, we want the key after /sp
      key = route.split('/')[2];
    }
    return key;
  }

  static buildRerouteUrl(reroutePath: string, routeParams: { [name: string]: number }) {
    // const routeParams: { [name: string]: number } = {};
    // this.getRouteConfig(route, routeParams);

    let rerouteUrl = reroutePath;
    if (routeParams['proposalId']) {
      rerouteUrl = rerouteUrl.replace(':proposalId', routeParams['proposalId'].toString());
    }
    if (routeParams['institutionId']) {
      rerouteUrl = rerouteUrl.replace(':institutionId', routeParams['institutionId'].toString());
    }
    if (routeParams['yearDurationId']) {
      rerouteUrl = rerouteUrl.replace(':yearDurationId', routeParams['yearDurationId'].toString());
    }
    if (routeParams['userId']) {
      rerouteUrl = rerouteUrl.replace(':userId', routeParams['userId'].toString());
    }
    if (routeParams['programKey']) {
      rerouteUrl = rerouteUrl.replace(':programKey', routeParams['programKey'].toString());
    }

    return rerouteUrl;
  }

  /**
   * Returns fully formatted route config as well as route params.
   * @private
   * @param {ActivatedRouteSnapshot} route
   * @param {{ [name: string]: number }} params - route params found
   * @returns {string}
   */
  static getRouteConfig(route: ActivatedRouteSnapshot, params: { [name: string]: number }): string {
    if (route.parent && route.parent.routeConfig) {
      const parentRouteConfig = this.getRouteConfig(route.parent, params);
      forOwn(route.params, (value, name) => {
        params[name] = Number(value) || value.toString();
      });
      return route.routeConfig.path ? `${parentRouteConfig}/${route.routeConfig.path}` : `${parentRouteConfig}`;
    } else {
      forOwn(route.params, (value, name) => {
        params[name] = Number(value) || value.toString();
      });
      return route.routeConfig && route.routeConfig.path ? `/${route.routeConfig.path}` : '';
    }
  }

  /**
   * Returns routing link to fiscal reporting.
   * @param {(Proposal | Model.ProposalItem)} proposal
   */
  static fiscalReportingLink(proposal: any) {
    if (proposal && proposal.id) {
      const fund = proposal.funds[0];
      let rootSegment;

      if (proposal.type === PROPOSAL_TYPES.FISCAL_AGENT) {
        rootSegment = 'fiscal-agents';
      } else if (fund.is_small_program && ![PROGRAM_KEYS.SWP_K12, PROGRAM_KEYS.PERKINS].includes(fund.parent_key)) {
        rootSegment = `${PROGRAM_KEYS.SMALL_PROGRAMS}/${fund.parent_key}`;
      } else {
        rootSegment = fund.parent_key || fund.key;
      }

      if ([PROGRAM_KEYS.PERKINS].includes(rootSegment)) {
        return `/${rootSegment}/${proposal.funds[0].key}/fiscal-reports/${proposal.id}`;
      } else if ([PROGRAM_KEYS.SWP_K12].includes(rootSegment)) {
        const subRoute = [PROGRAM_KEYS.SWP_K12_TAP, PROGRAM_KEYS.SWP_K12_PC].includes(fund.key) ? 'tap-pc' : 'plans';
        return `/${rootSegment}/fiscal-reports/${subRoute}/${proposal.id}`;
      } else {
        return `/${rootSegment}/fiscal-reports/${proposal.id}`;
      }
    } else {
      return '';
    }
  }

  /**
   * Converts date to local string. Deprecated for display purpose in favor of shared.generic/pipes/dateTransform.pipe.ts which can be inlined in to the HTML as a pipe.
   * @param value
   * @param defaultToNow use now as default date if true
   * @param shortOptions use short formatting options, otherwise use long options.
   */
  static convertDateToString(value: Date|string, defaultToNow = false, shortOptions = false): string {
    // assign formatting type.
    let dateOptions: Intl.DateTimeFormatOptions;
    if (shortOptions) {
      dateOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      };

    } else {
      dateOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
        timeZone: 'UTC',
      };
    }

    // convert date to string.
    const convert = (toDate: any) => {
      if (isString(toDate)) {
        return new Date(toDate).toLocaleDateString('en-US', dateOptions);
      } else {
        return toDate.toLocaleDateString('en-US', dateOptions);
      }
    };

    return !Utilities.isNil(value) ? convert(value)
      : defaultToNow ? new Date().toLocaleDateString('en-US', dateOptions)
      : 'N/A';
  }

  /**
   * Use this instead of (error) => setAppError in your api calls.
   * @param store. The redux store.
   * @param location. The constructor.name of the caller
   * @param message. Custom message to show
   * @returns {(error:any)=>undefined}
   */
  static setCallbackError(store: any, location: string, message?: string) {
    const result = (error) => {
      store.dispatch(Actions.Layout.showBusySpinner(false))
      store.dispatch(Actions.App.setError({
        type: EnumErrorTypes.api,
        location: location,
        show: true,
        raw: error,
        message,
      }))
    };
    return result;
  }

  /**
   * Returns true if value is undefined or is null.
   * @param value
   */
  static isNil(value: any): boolean {
    return AppUtils.isNil(value);
  }

  /**
   * Formats a user object for use in po-select menu
   * @param contact Contact item, must use any to deal with AEBG contact
   * @returns {Model.SelectOption} Formatted option for look-ahead
   */
  static formatContact(contact: Model.User|any): Model.SelectOption {
    return AppUtils.formatContact(contact);
  }

  /**
   * Returns newline-delimited string of institution names. For use on fund project pages.
   * @param institutions. An array of institutions, must have field `name`
   * @returns {string}
   */
  static formatProposalInstitutions(institutions: Array<Model.Institution>, lead_institution_id?: number) {
    if (institutions && institutions.length > 0) {
      return institutions.map(inst => {
        const name = inst.name;
        return inst.id === lead_institution_id
          ? name.concat(' (Lead)')
          : name;
      }).join('\n');
    } else {
      return 'N/A';
    }
  }

  static formatAssuranceText(text: string) {
    const paragraphs = text.split(/<p[^>]*>/g); // Split on each p-tag
    if (paragraphs[0] === '') {
      paragraphs.shift(); // Remove first match (empty paragraph)
    }

    return paragraphs.join('<br>') // Add breaks between each paragraph
      .replace(/<\/?p[^>]*>/g, ''); // Remove p-tags to remove extra newlines
  }

  /**
   * Returns true if id is app recognized temp id.
   * @param id
   */
  static isTempId(id: number): boolean {
    return NewTempId.isTemp(id);
  }

  /**
   * Filters out temp records from the list.
   * @param collection
   */
  static filterOutTempIds(list: Array<any>): Array<any> {
    const result = list ? list.filter(item => !Utilities.isTempId(item.id)) : [];
    return result;
  }

  /**
   * Returns number value or null, rounded if required.
   * @param before: unmodified value
   * @param round: boolean, round value if set
   */
  static numberValue(before: number | string, round: boolean = false) {
    if (typeof before === 'string') {
      before = before.replace(NUM_REGEX, '')
    }
    const val = Number(before);
    if (!val) {
      return null;
    } else {
      return round ? Math.round(val) : val
    }
  }

  /**
   * Strips tags from the html string.
   * @param value
   */
  static stripTags(value: string): string {
    const elem = document.createElement('elem');
    elem.innerHTML = value;
    const text = elem.textContent || elem.innerText || '';
    return text;
  }

  /**
   * Strips time portion out of the iso formatted date string.
   * @param value
   */
  static stripTime(value: string): string {
    return !Utilities.isNil(value) ? value.substring(0, DATE_TIME_MOMENT_FORMAT_STRINGS.isoDate.length) : '';
  }

  /**
   * Converts time 12 hrs <=> 24 hrs.
   * @param time
   * @param to24Hours
   */
  static convertTime(time: string, to24Hours?: boolean): string {
    if (Utilities.isNil(time)) {
      // don't convert null string.
      return time;
    }

    return to24Hours
      ? moment(time, [DATE_TIME_MOMENT_FORMAT_STRINGS.time12Hrs]).format(DATE_TIME_MOMENT_FORMAT_STRINGS.time24Hrs)
      : moment(time, [DATE_TIME_MOMENT_FORMAT_STRINGS.time24Hrs]).format(DATE_TIME_MOMENT_FORMAT_STRINGS.time12Hrs);
  }

  /**
   * Converts iso date & time in utc to NOVA timezone.
   * @param isoString
   */
  static convertToNOVADateTime(isoString: string, useFormatString?: string) {
    const format = useFormatString
      ? useFormatString
      : DATE_TIME_MOMENT_FORMAT_STRINGS.dateTime12HoursTZ;
    const dateTimeString = !Utilities.isNil(isoString)
      ? moment.tz(isoString, DATE_TIME_MOMENT_FORMAT_STRINGS.NOVA_timezone).format(format)
      : '';
    return dateTimeString;
  }

  static nowToNOVADateTime() {
    return Utilities.convertToNOVADateTime(moment().toISOString());
  }

  static formatDate(isoString: string, useFormatString?: string) {
    const format = useFormatString
      ? useFormatString
      : DATE_TIME_MOMENT_FORMAT_STRINGS.dateLong;
    const dateTimeString = !Utilities.isNil(isoString)
      ? moment.utc(isoString).format(format)
      : '';
    return dateTimeString;
  }

  static calcPercent(value: number, maximum: number): number {
    if (value <= 0) {
      return 0;
    } else if ((maximum <= 0) || (value > maximum)) {
      return 100;
    } else {
      return (value / maximum) * 100;
    }
  }

  static titleCase(input: string): string {
    if (input && input.length) {
      return input.charAt(0).toUpperCase() + input.substr(1).toLowerCase();
    }
  }

  static between = (value: number, min: number, max: number) => {
    // Check between min and max, or just min if no max defined
    if (value >= min && (max != null ? value <= max : true)) {
      return true;
    }
    return false;
  };

  static hasDuplicates(arr: any[]) {
    return uniq(arr).length !== arr.length;
  }
}
